body {
  font-family: 'Source Sans Pro', sans-serif;
}

/* div {
  border: 0.1px dotted grey;
} */

.settingsouter form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

@keyframes slideUpFooter {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    bottom: translateY(0);
    opacity: 1;
  }
}

@keyframes pulseOpacity {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}

@keyframes slideDismiss {
  from {
    transform: translate(0, 0);
    opacity: 0.5;
  }
  to {
    transform: translate(0, 0);
    opacity: 0;
  }
}

@keyframes fadeDismiss {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }
  to {
    transform: translate(-100%, 0);
    opacity: 0;
  }
}

@keyframes popIn {
  from {
    transform: scale(0);
  }

  to {
    bottom: scale(1);
  }
}

.issueExpanded + .issueExpanded {
  margin-top: 20px;
}

.issueExpanded + .issueCollapsed {
  margin-top: 20px;
}

.issueCollapsed + .issueExpanded {
  margin-top: 20px;
}

/* For the icons on the Issue Details Actions Buttons */
.ant-btn svg:first-child {
  margin: 0 2px 1px 0;
}
.ant-btn svg:last-child {
  margin: 0 0 1px 2px;
}

.ant-btn.ant-btn-sm svg:first-child {
  margin: 0 0px 1px 0;
}
.ant-btn.ant-btn-sm svg:last-child {
  margin: 0 0 1px 0px;
}

.ant-btn.ant-btn-sm:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding: 6px;
  font-size: 12px;
  line-height: 12px;
}
.ant-btn.ant-btn-sm:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only i.anticon {
  line-height: 12px;
  vertical-align: top;
}

.no-wrap { 
  white-space: nowrap;
}


/* REACT TRANSITION GROUP STYLES */
.page-appear {
  opacity: 0.01;
}

.page-appear.page-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.enter-fade-in-appear {
  opacity: 0.01;
  transform: translateY(10px);
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.enter-fade-in-appear.enter-fade-in-appear-active {
  opacity: 1;
  transform: translateY(0);
}

.ant-popover-no-arrow .ant-popover-arrow {
  display: none;
}

/* .example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
} */



/* .pane.example-enter, */
.pane.example-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
}

.enter-left.example-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.enter-left.example-enter.example-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.enter-left.example-leave {
  opacity: 1;
  transform: translateX(0);
}

.enter-left.example-leave.example-leave-active {
  opacity: 0.01;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.enter-right.example-enter {
  opacity: 0.01;
  transform: translateX(100%);
}

.enter-right.example-enter.example-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.enter-right.example-leave {
  opacity: 1;
  transform: translateX(0);
}

.enter-right.example-leave.example-leave-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

.link-button {
  background-color: transparent;
  border: none;
  color: #673AB7;
  cursor: pointer;
  /* text-decoration: underline; */
  display: inline;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

/* 
  For Antd's TreeSelect
  
  To use: Add class .cascade-checks to TreeSelect's dropdown element via `dropdownClassName`.
  
  What it does: This causes the checking of a parent item in the tree to fill child items with a square
  indicating they are implicitly included by selecting a parent item.
  
  Why: Necessary to be a global style because its the easiest way to inject complex styles until 
  we upgrade to Antd 4.0+ where the `dropdownRenderer` property should actually work.
*/
.ant-select-dropdown.cascade-checks .ant-select-tree-treenode-checkbox-checked .ant-select-tree-child-tree .ant-select-tree-checkbox:not(.ant-select-tree-checkbox-checked) .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}

.ant-select-dropdown.cascade-checks .ant-select-tree-treenode-checkbox-checked .ant-select-tree-child-tree .ant-select-tree-checkbox:not(.ant-select-tree-checkbox-checked) .ant-select-tree-checkbox-inner:after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #673ab7;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.mono {
  font-family: monospace;
  font-size: 13px;
}

.no-bullets {
  padding: 0;
  margin: 0
}

.no-bullets li {
  list-style-type: none;
}
